html, body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-size: 18px;
}

#container {
  position: relative;
  height: auto !important;
}

#header {
  text-align: center;
}

#content {
  text-align: center;
  width: 600px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

#footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}

body, input, button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto Condensed, sans-serif;
}

h2 {
  text-align: center;
  font-size: 1.5rem;
}

h3 {
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px solid #000;
  font-size: 1rem;
}

input {
  border: 1px solid #000;
}

input, button {
  color: #000;
  margin: .3rem 0;
  padding: .7rem 1rem;
  font-size: 1rem;
}

button {
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0);
}

button:hover, .WXqopW_product-header:hover {
  color: #fff;
  cursor: pointer;
  transition: background-color .5s;
  background-color: #1c3490 !important;
}

label {
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  display: flex;
}

input[type="radio"] {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin: .3rem 1rem .3rem 0;
}

a, a:visited {
  color: #00f;
  cursor: pointer;
  text-decoration: underline;
}

.WXqopW_collapsable {
  transition: height .3s;
  overflow: hidden;
}

.WXqopW_visible-true {
  display: auto;
}

.WXqopW_visible-false {
  display: none;
}

.WXqopW_sticky {
  text-align: center;
  background-color: #fff;
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.WXqopW_horizontal-spacing > * {
  margin: 0 .5rem;
}

.WXqopW_notices {
  font-weight: bold;
}

.WXqopW_notices > span:after {
  content: " ";
}

.WXqopW_menu {
  text-align: left;
  padding-bottom: 2rem;
}

.WXqopW_product {
  margin-bottom: 1rem;
}

.WXqopW_product.WXqopW_selected-true {
  border-bottom: 2px solid #1c3490;
}

.WXqopW_product.WXqopW_selected-false .WXqopW_collapsable {
  height: 0;
}

.WXqopW_product .WXqopW_product-header {
  cursor: pointer;
  background-color: #f5f5f5;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  display: flex;
}

.WXqopW_product.WXqopW_selected-true .WXqopW_product-header {
  color: #fff;
  background-color: #1c3490;
}

.WXqopW_product .WXqopW_product-header .WXqopW_details {
  color: gray;
  margin-left: 10px;
  font-size: smaller;
  display: inline-block;
}

.WXqopW_product .WXqopW_product-header:hover .WXqopW_details {
  color: #fff !important;
}

.WXqopW_product.WXqopW_selected-true .WXqopW_product-header .WXqopW_details {
  color: #fff;
}

.WXqopW_product-content {
  text-align: center;
  height: 0;
  margin: 0 1rem 1rem;
}

.WXqopW_product-content .WXqopW_customisations > div {
  text-align: left;
  margin: 1.5rem 0;
}

.WXqopW_product-content .WXqopW_customisations label {
  align-items: center;
  display: flex;
}

.WXqopW_option-price {
  margin-left: 1rem;
}

.WXqopW_quantity-bar {
  text-align: center;
  margin-top: 1rem;
}

.WXqopW_quantity {
  width: 2rem;
  display: inline-block;
}

form.WXqopW_booking {
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
  padding: 1rem;
}

form.WXqopW_booking > div {
  padding-bottom: 1rem;
  position: relative;
}

form.WXqopW_booking label {
  padding-bottom: .2rem;
  font-weight: bold;
}

form.WXqopW_booking label:after {
  content: ":";
}

form.WXqopW_booking input {
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
}

form.WXqopW_booking .WXqopW_example {
  text-align: right;
  width: 100%;
  font-size: .8rem;
  position: absolute;
}

form button {
  width: 100%;
}

.WXqopW_your-order {
  margin: 1rem;
}

.WXqopW_your-order .WXqopW_items {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin: 2rem 0;
  padding: 1rem 0;
}

.WXqopW_your-order .WXqopW_item-summary {
  padding: .2rem 0;
}

.WXqopW_your-order.WXqopW_readonly-true {
  text-align: center;
}

.WXqopW_your-order.WXqopW_readonly-false .WXqopW_item-summary {
  text-align: left;
  justify-content: space-between;
  display: flex;
}

.WXqopW_your-order .WXqopW_item-description {
  padding: 1rem 0;
}

.WXqopW_paypal {
  padding: 1rem;
}

.WXqopW_error-message {
  background-color: pink;
  padding: 1rem;
  font-weight: bold;
}

.WXqopW_thankyou {
  font-size: 2rem;
}

@media screen and (max-device-width: 600px) {
  #content {
    width: auto;
  }

  #footer {
    font-size: .8rem;
  }
}

/*# sourceMappingURL=index.7d56288b.css.map */
